<template>
  <div>
    <h2>
      <v-icon large color="primary --text" class="mr-1 pr-1">{{
        pageTitle.icon
      }}</v-icon
      >{{ pageTitle.name }}
    </h2>
  </div>
</template>

<script>
export default {
  name: "PageTitle",
  props: {
    title: { type: String, required: true },
  },

  data() {
    return {};
  },

  methods: {},

  created() {},

  computed: {
    pageTitle() {
      return this.$mixins.pageTitles(this.title);
    },
  },
};
</script>
